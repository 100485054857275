import React from 'react';
import { Helmet } from 'react-helmet';
import { VStack } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

const IndexPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>veidemanis.lv</title>
                <meta name="description" content="veidemanis.lv main site" />
            </Helmet>
            <VStack h="100vh" w="100vw" bg="gray.700" justifyContent="center">
                <StaticImage
                    src="../images/logo.png"
                    alt="main site logo"
                    height={200}
                />
            </VStack>
        </>
    );
};

export default IndexPage;
